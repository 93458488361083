import React, { useEffect, useState } from 'react';

import {
  useGetAccount,
  useGetActiveTransactionsStatus,
} from '@multiversx/sdk-dapp/hooks';

import { Loader } from '@multiversx/sdk-dapp/UI';
import axios, { AxiosError } from 'axios';

import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { contractNFTAddress, NFT_TOKEN_ID, MULTIVERSX_API_URL } from 'config';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Address, TransactionPayload } from '@multiversx/sdk-core/out';
import { numHex } from 'utils/numHex';
import { NFTToUnstake } from 'types/NFTToUnstake';
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';

export const NftsOnChain = () => {
  const { address } = useGetAccount();
  const { success, fail } = useGetActiveTransactionsStatus();

  const [nfts, setNfts] = useState<NftType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectable, setSelectable] = useState<boolean>(false);
  const [nftsToStake, setNftsToStake] = useState<NFTToUnstake[]>([]);
  const [error, setError] = useState<string>();

  const fetchNfts = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(MULTIVERSX_API_URL + '/accounts/' + address + '/nfts?size=8050&collection=' + NFT_TOKEN_ID);
      setNfts(data);
    } catch (err) {
      const { message } = err as AxiosError;
      setError(message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (success || fail) {
      fetchNfts();
    }
  }, [success, fail]);

  useEffect(() => {
    fetchNfts();
  }, []);

  const includeNft = (nonce: number) => {
    for(const nft of nftsToStake) {
      if(nft.nonce === nonce) return true;
    }
    return false;
  }

  const handleChange = (identifier: string, nonce: number) => {
    if(includeNft(nonce)) {
      setNftsToStake((prev) => [...prev.filter((nft) => nft.nonce !== nonce)]);
    } else {
      setNftsToStake((prev) => [...prev, {identifier, nonce}])
    }
  }
  
  const createData = () => {
    let data = 'MultiESDTNFTTransfer@' + new Address(contractNFTAddress).hex() + "@" + numHex(nftsToStake.length);

    for(const nft of nftsToStake) {
      data += "@" + Buffer.from(NFT_TOKEN_ID).toString("hex") + "@" + numHex(nft.nonce) + "@" + numHex(1);
    }
    return data;
  }

  const sendStakeTransaction = async () => {
    const stakeTransaction = {
      value: 0,
      data: new TransactionPayload(createData() + "@" + Buffer.from('stake').toString("hex")),
      receiver: address,
      gasLimit: 60000000 
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: stakeTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Stake transaction',
        errorMessage: 'An error has occured during Stake',
        successMessage: 'Stake transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      // setTransactionSessionId(sessionId);
    }
    setNftsToStake([]);
    setSelectable(false);
  };

  if (isLoading) {
    return <Loader className='loader' noText />;
  }

  if (nfts.length === 0) {
    return (
      <div className='p-4'>
        <h5 className='text-white text-center font-trocchi mb-5'>OPS! You don't have any xApes in your wallet.</h5>
        <a href="https://xoxno.com/collection/XAPES-e3aceb" target='_blank' rel='noref'>
          <img
            src={require('../../../../assets/img/buy-image.png')}
            width={'100%'}
            alt="buy"
            className='d-block mx-auto nft-image'
          />
        </a>
      </div>
    );
  }

  return <>
          <Row>
          <Col>
            {selectable ? (
            <div className='d-flex justify-content-center mb-3'>
              <Button className='btn-custom font-studly-regular' onClick={() => {setSelectable(false), setNftsToStake([])}}>
                Cancel
              </Button>
              {nftsToStake.length > 0 ? <Button className='btn-custom font-studly-regular ml-2' onClick={sendStakeTransaction}>
                Stake
              </Button> : null}
            </div>
            ) : <Button className='btn-custom font-studly-regular d-block mx-auto mb-3' onClick={() => setSelectable(true)}>
              Select
            </Button>}
          </Col>
        </Row>
        <Row>
            {nfts?.map((nft) => (
              <Col sm={12} md={3} key={nft.identifier + nft.nonce}>
                {selectable ? (
                  <div
                  onClick={() => handleChange(nft.identifier, nft.nonce)}
                >
                  <img src={nft.url} width={'100%'} className={includeNft(nft.nonce) ? "nft-selcted" : undefined} />
                  <p className='text-center text-white font-trocchi mt-2'>{nft.identifier + '-' + nft.nonce}</p>
                </div>
                ) : (
                  <div>
                    <img src={nft.url} width={'100%'} />
                    <p className='text-center text-white font-trocchi mt-2'>{nft.identifier + '-' + nft.nonce}</p>
                  </div>
                )}
              </Col>
            ))}
        </Row>
  </>
};
