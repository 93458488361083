import React, { useState, useEffect } from 'react';

const getTimeRemaining = (deadline: number) => {
    const totalSeconds = Math.floor((deadline - Date.now()) / 1000);
    const seconds = totalSeconds % 60;
    const minutes = Math.floor((totalSeconds / 60) % 60);
    const hours = Math.floor((totalSeconds / 3600) % 24);
    const days = Math.floor(totalSeconds / (3600 * 24));

    return {
      total: totalSeconds,
      days,
      hours,
      minutes,
      seconds
    };
  };

const Timer = ({ deadline }: { deadline: number }) => {
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(deadline));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(getTimeRemaining(deadline));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [deadline]);


  return (
    <div>
      {timeRemaining.total <= 0 ? (
        <p className='text-white text-center font-trocchi'>It's time to unbond!</p>
      ) : (
        <p className='text-white text-center font-trocchi'>
          {timeRemaining.days} days, {timeRemaining.hours} hours, {timeRemaining.minutes} minutes, {timeRemaining.seconds} seconds
        </p>
      )}
    </div>
  );
};

export default Timer;
