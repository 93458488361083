export const contractSFTAddress =
  'erd1qqqqqqqqqqqqqpgqnazwq686e4r58750mwm5hef4lsdwr4rmd8as3qn9eu';
export const contractNFTAddress = 'erd1qqqqqqqqqqqqqpgq0kya45lj7kn25pmw6lakeqej7awxjhkgd8as64csla';

  // x - total cantitate reward
  // y - total nfts staked
  // formula = x / y / 30 zile
  // daca se adauga rewards sa se adune ce a ramas din tokenul acela plus ce adauga

export const dAppName = 'xApes';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';

export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.multiversx.com';
export const MULTIVERSX_API_URL = 'https://api.multiversx.com';
export const MICROSERVICE_API_URL = 'https://estar.games:3140';
export const SFT_TOKEN_MEDIA = 'https://media.elrond.com/nfts/thumbnail/XAC-db0359-73c1217f';
export const SFT_TOKEN_ID = 'XAC-db0359';
export const NFT_TOKEN_ID = 'XAPES-e3aceb';
/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];
