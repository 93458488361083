import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SftsStaked } from './components/SftsStaked';
import { SftsOnChain } from './components/sfts.on.chain/SftsOnChain';
import { SftsUnbond } from './components/SftsUnbond';
import { fetchUserRewardsForSftsStaked } from 'utils/fetchUserRewardsForSftsStaked';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { RewardCard } from 'components/RewardCard';
import { contractSFTAddress } from 'config';

export const Sfts = () => {
  const { address } = useGetAccountInfo();
  const [rewards, setRewards] = useState(0);

  useEffect(() => {
    fetchUserRewardsForSftsStaked(address, setRewards);
}, [])

  return <Container>
    <Row>
      <Col sm={12} className="my-4">
        <RewardCard rewards={rewards} contractAddress={contractSFTAddress} />
      </Col>
    </Row>
    <Row>
      <Col sm={12}>
        <div className='reward-card my-3 py-4'>
          <Row>
            <Col sm={12} lg={6} className='mb-5 mb-md-0'>
              <h2 className='text-center text-white font-studly-regular'>XCARD STAKED</h2>
              <SftsStaked />
            </Col>
            <Col sm={12} lg={6} className='mb-4 mb-md-0'>
              <h2 className='text-center text-white font-studly-regular'>XCARD UNBOND</h2>
              <SftsUnbond />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
    <Row>
      <Col sm={12}>
        <div className='reward-card p-4'>
          <h2 className='text-center text-white font-studly-regular'>YOUR XCARD</h2>
          <SftsOnChain />
        </div>
      </Col>
    </Row>
  </Container>
};
