import React, { useEffect, useState } from 'react';

import {
  useGetAccount,
  useGetActiveTransactionsStatus,
} from '@multiversx/sdk-dapp/hooks';

import { Loader } from '@multiversx/sdk-dapp/UI';
import axios, { AxiosError } from 'axios';

import { MULTIVERSX_API_URL, SFT_TOKEN_ID } from 'config';
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { contractSFTAddress } from 'config';
import { Button, Modal } from 'react-bootstrap';
import { Address, TransactionPayload } from '@multiversx/sdk-core/out';
import { numHex } from 'utils/numHex';
import { SFT_TOKEN_MEDIA } from '../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

export const SftsOnChain = () => {
  const { address } = useGetAccount();
  const { success, fail } = useGetActiveTransactionsStatus();

  const [sft, setSft] = useState<NftType>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [show, setShow] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(1);

  const fetchSfts = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(MULTIVERSX_API_URL + '/accounts/' + address + '/nfts?size=550&collection=' + SFT_TOKEN_ID);
      setSft(data[0]);
    } catch (err) {
      const { message } = err as AxiosError;
      setError(message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (success || fail) {
      fetchSfts();
    }
  }, [success, fail]);

  useEffect(() => {
    fetchSfts();
  }, []);

  const increase = () => {
    if(amount >= Number(sft?.balance)) {
      return;
    }
    setAmount((prev) => prev + 1);
  }

  const decrease = () => {
    if(amount === 1) {
      return;
    }
    setAmount((prev) => prev - 1);
  }


  const sendStakeTransaction = async () => {
    const data = "ESDTNFTTransfer" + "@" + Buffer.from(SFT_TOKEN_ID).toString("hex") + "@" + numHex(1) + "@" + numHex(amount) + "@" + new Address(contractSFTAddress).hex() + "@" + Buffer.from("stake").toString("hex");
    const stakeTransaction = {
      value: 0,
      data: new TransactionPayload(data),
      receiver: address,
      gasLimit: '60000000'
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: stakeTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Stake transaction',
        errorMessage: 'An error has occured during Stake',
        successMessage: 'Stake transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      // setTransactionSessionId(sessionId);
    }
    setAmount(1);
    setShow(false);
  };

  if (isLoading) {
    return <Loader className='loader' noText />;
  }

  if (!sft) {
    return (
      <div className='p-4'>
        <h5 className='text-white text-center font-trocchi mb-5'>OPS! You don't have any xCard in your wallet.</h5>
        <a href="https://xoxno.com/collection/XAC-db0359" target='_blank' rel='noref'>
          <img
            src={require('../../../../assets/img/buy-image.png')}
            width={'100%'}
            alt="buy"
            className='d-block mx-auto nft-image'
          />
        </a>
      </div>
    );
  }

  return <>
    <img src={SFT_TOKEN_MEDIA} width="100%" className='mb-3 d-block mx-auto nft-image' alt="sft" />
    <h5 className='text-white text-center font-trocchi mb-3'>x{sft?.balance}</h5>
    <button className='btn-custom font-studly-regular d-block mx-auto' onClick={() => setShow(true)}>Stake</button>
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdropClassName='jungleversx-modal'
      className='jungleversx'
      centered
    >
        <Modal.Body className='jungleversx-modal-body'>
          <h2 className='text-center text-white font-studly-regular mt-2 mb-4'>Set amount</h2>
          <div className="mb-5">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faMinus} fontSize={30} color='#29F9DE' onClick={decrease} />
              <h1 className='text-center text-white font-studly-regular mx-3'>{amount}</h1>
              <FontAwesomeIcon icon={faPlus} fontSize={30} color='#29F9DE' onClick={increase} />
            </div>
            <Button
              className='btn-custom font-studly-regular d-block mx-auto'
              onClick={() => setAmount(Number(sft?.balance))}
            >Max</Button>
            {error && <div className="form-text">{error}</div>}
          </div>
          <div className="d-flex justify-content-end">
            <Button variant='outline' className='text-white font-trocchi' onClick={() => setShow(false)}>
              Close
            </Button>
            <Button className='btn-custom font-studly-regular' disabled={error ? true : false} onClick={sendStakeTransaction}>
              Stake
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
};
