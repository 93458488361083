import React, {useEffect, useState} from 'react'
import { Card } from 'react-bootstrap'
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import axios from 'axios';
import { MULTIVERSX_API_URL } from '../config';

export const NftImage = ({ identifier, className }: { identifier: string, className?: string }) => {
    const [nft, setNft] = useState<NftType>()

    const fetchNftDetails = async () => {
        try {
            const { data } = await axios.get(MULTIVERSX_API_URL + '/nfts/' + identifier);
            setNft(data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchNftDetails()
    }, [])

  return <img src={nft?.url} className={className} width="100%" />
}
