import axios from "axios";
import { MICROSERVICE_API_URL } from '../config';

export const fetchUserRewardsForNftsStaked = async (address: string, setData: (arg: any) => void) => {
    try {
        const { data } = await axios.get(MICROSERVICE_API_URL + '/stake/nfts/users/' + address + '/rewards');
        setData(data);
    } catch (error) {
        console.log(error);
    }
}