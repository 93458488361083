import * as React from 'react';
import { AuthRedirectWrapper } from 'components';
import { dAppName, walletConnectV2ProjectId } from 'config';
import { routeNames } from 'routes';
import { ExtensionLoginButton, LedgerLoginButton, OperaWalletLoginButton, WalletConnectLoginButton, WebWalletLoginButton } from '@multiversx/sdk-dapp/UI';

const HomePage = () => {
  const commonProps = {
    callbackRoute: routeNames.sfts,
    nativeAuth: true // optional
  };

  return (
    <div className='container d-flex align-items-center justify-content-center' style={{
      height: '90vh'
    }}>
      <div className='row'>
        <div className='col-12'>
          <div className='home-card'>
            <div className='text-center'>
              <img src={require('../assets/img/xapes-block.png')} width={200} height={200} />

              <p className='mb-3 text-white font-trocchi'>
                Welcome to xApes.
                <br /> Login using your MultiversX wallet.
              </p>

              <ExtensionLoginButton
              loginButtonText='Extension'
              className='btn-login-custom'
              {...commonProps}
            />

            <OperaWalletLoginButton
              loginButtonText='Opera Crypto Wallet - Beta'
              className='btn-login-custom'
              {...commonProps}
            />

            <WebWalletLoginButton
              loginButtonText='Web wallet'
              className='btn-login-custom'
              {...commonProps}
            />
            <LedgerLoginButton
              loginButtonText='Ledger'
              className='btn-login-custom'
              {...commonProps}
            />
            <WalletConnectLoginButton
              loginButtonText='xPortal'
              className='btn-login-custom'
              {...commonProps}
              {...(walletConnectV2ProjectId
                ? {
                    isWalletConnectV2: true
                  }
                : {})}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Home = () => (
  <AuthRedirectWrapper>
    <HomePage />
  </AuthRedirectWrapper>
);
