import React, { useEffect, useState } from 'react';
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import axios, { AxiosError } from 'axios';
import { contractSFTAddress, MICROSERVICE_API_URL, SFT_TOKEN_MEDIA, SFT_TOKEN_ID } from 'config';
import { Button, Modal } from 'react-bootstrap';
import { SFTStaked } from 'types/SFTStaked';
import { TransactionPayload } from '@multiversx/sdk-core/out';
import { numHex } from 'utils/numHex';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

export const SftsStaked = () => {
  const { address } = useGetAccountInfo();
  const { success, fail } = useGetActiveTransactionsStatus();
  const [sft, setSft] = useState<SFTStaked>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [show, setShow] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(1);

  const fetchSftsStaked = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(MICROSERVICE_API_URL + '/stake/sfts/users/' + address);
      setSft(data);
    } catch (err) {
      const { message } = err as AxiosError;
      setError(message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (success || fail) {
      fetchSftsStaked()
    }
  }, [success, fail]);

  useEffect(() => {
    fetchSftsStaked();
  }, []);

  const increase = () => {
    if(amount >= Number(sft?.amount)) {
      return;
    }
    setAmount((prev) => prev + 1);
  }

  const decrease = () => {
    if(amount === 1) {
      return;
    }
    setAmount((prev) => prev - 1);
  }

  const sendUnStakeTransaction = async () => {
    const data = "unStake" + "@" + Buffer.from(SFT_TOKEN_ID).toString("hex") + "@" + numHex(1) + "@" + numHex(amount);
    const unStakeTransaction = {
      value: 0,
      data: new TransactionPayload(data),
      receiver: contractSFTAddress,
      gasLimit: '60000000'
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: unStakeTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing UnStake transaction',
        errorMessage: 'An error has occured during UnStake',
        successMessage: 'UnStake transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      // setTransactionSessionId(sessionId);
    }

    setAmount(1);
    setShow(false);
  };

  if (!sft) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
        <img
          src={require('../../../assets/img/no-nft.png')}
          alt='no nfts'
          width={'30%'}
          className='mb-4'
        />
      </div>
    );
  }

  return (
    <>
      <div>
        <img src={SFT_TOKEN_MEDIA} alt="sft" width={'100%'} className='d-block mx-auto nft-image mb-3' />
        <h5 className='text-white text-center font-trocchi mb-3'>x{sft.amount}</h5>
        <button className='btn-custom font-studly-regular d-block mx-auto'  onClick={() => setShow(true)}>UnStake</button>
      </div>
      <Modal
      show={show}
      onHide={() => setShow(false)}
      backdropClassName='jungleversx-modal'
      className='jungleversx'
      centered
    >
        <Modal.Body className='jungleversx-modal-body'>
          <h2 className='text-center text-white font-studly-regular mt-2 mb-4'>Set amount</h2>
          <div className="mb-5">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faMinus} fontSize={30} color='#29F9DE' onClick={decrease} />
              <h1 className='text-center text-white font-studly-regular mx-3'>{amount}</h1>
              <FontAwesomeIcon icon={faPlus} fontSize={30} color='#29F9DE' onClick={increase} />
            </div>
            <Button
              className='btn-custom font-studly-regular d-block mx-auto'
              onClick={() => setAmount(Number(sft.amount))}
            >Max</Button>
            {error && <div className="form-text">{error}</div>}
          </div>
          <div className="d-flex justify-content-end">
            <Button variant='outline' className='text-white font-trocchi' onClick={() => setShow(false)}>
              Close
            </Button>
            <Button className='btn-custom font-studly-regular' disabled={error ? true : false} onClick={sendUnStakeTransaction}>
              UnStake
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
