import React, { useState } from 'react';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import { Navbar as BsNavbar, NavItem, Container, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { dAppName } from 'config';
import { routeNames } from 'routes';
import { ReactComponent as Logo } from '../../../assets/img/xapes.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';

export const Navbar = () => {
  const isLoggedIn = useGetIsLoggedIn();
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    logout(`${window.location.origin}/`);
  };

  return (
    <>
      <BsNavbar variant='dark' className='px-4 py-2' sticky="top" style={{ background: 'rgba(0, 0, 0, 0.3)'}} expand="md">
        <Container fluid>
          <Link
            className='d-flex align-items-center navbar-brand mr-0'
            to={isLoggedIn ? routeNames.sfts : routeNames.home}
          >
            <Logo className='xapes-logo' />
          </Link>
          <BsNavbar.Toggle />
          <BsNavbar.Collapse>
            <div className='ml-auto mt-4 mt-md-0 d-flex flex-column flex-md-row align-items-center'>
              {isLoggedIn && (
                <>
                  <NavItem>
                    <button className='btn-custom' onClick={() => setShow(true)}>
                      GAME
                    </button>
                  </NavItem>
                  <NavItem>
                    <Link to={routeNames.sfts}>
                      <button className='btn-custom ml-md-3'>
                        xCard
                      </button>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to={routeNames.nfts}>
                      <button className='btn-custom ml-md-3'>
                        xApes
                      </button>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <button className='btn-custom ml-md-3' onClick={handleLogout}>
                      <FontAwesomeIcon icon={faPowerOff} fontSize={30} />
                    </button>
                  </NavItem>
                </>
              )}
            </div>
          </BsNavbar.Collapse>
        </Container>
      </BsNavbar>
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        centered
        backdropClassName='jungleversx-modal'
        className='jungleversx'
        size='xl'
      >
        <Modal.Body className='jungleversx-modal-body'>
          <div className='d-flex flex-column align-items-center justify-content-center'>
                <h2 className='jungleversx-sub'>Coming Soon</h2>
                <h1 className='jungleversx-title'>Jungleversx</h1>
                <p className='text-white jungleversx-info'>Welcome, adventurous soul!</p>
                <p className='text-white jungleversx-info'>Prepare to embark on an epic journey through the untamed wilderness of JungleversX! Our team of intrepid developers is putting the finishing touches on this thrilling game , that will whisk you away to a world of excitement and challenge.</p>
                <p className='text-white jungleversx-info'>Swap, match, and crush your way through the lush jungles, solving captivating puzzles and uncovering hidden treasures. </p>
                <p className='text-white jungleversx-info'>The countdown to adventure has begun! Stay tuned for the grand unveiling of JungleversX, where thrilling gameplay and captivating quests await you.</p>
                <p className='text-white jungleversx-info'>Gather your courage, for the jungle calls! Thank you for your patience, and we promise this wild experience will be well worth the wait.</p>
                <p className='text-white jungleversx-info'>Adventure awaits! See you soon in JungleversX!</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
