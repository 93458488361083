import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NftsOnChain } from './components/nfts.on.chain'
import { NftsStaked } from './components/NftsStaked'
import { NftsUnbond } from './components/NftsUnbond'
import { RewardCard } from 'components/RewardCard'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { fetchUserRewardsForNftsStaked } from 'utils/fetchUserRewardsForNftsStaked'
import { contractNFTAddress } from 'config'

export const Nfts = () => {
  const { address } = useGetAccountInfo();
    const [rewards, setRewards] = useState<number>(0);

    useEffect(() => {
        fetchUserRewardsForNftsStaked(address, setRewards);
    }, [])

  return <Container>
  <Row>
    <Col sm={12} className="my-4">
      <RewardCard rewards={rewards} contractAddress={contractNFTAddress} />
    </Col>
  </Row>
  <Row>
    <Col sm={12} className="my-4">
      <div className="reward-card py-4">
        <h1 className='text-center text-white font-studly-regular'>XAPES STAKED</h1>
        <NftsStaked />
      </div>
    </Col>
  </Row>
  <Row>
    <Col sm={12} className="my-4">
      <div className="reward-card py-4">
        <h1 className='text-center text-white font-studly-regular'>XAPES UNBOND</h1>
        <NftsUnbond />
      </div>
    </Col>
  </Row>
  <Row>
    <Col sm={12}>
      <div className="reward-card py-4">
        <h1 className='text-center text-white font-studly-regular'>YOUR XAPES</h1>
        <NftsOnChain />
      </div>
    </Col>
  </Row>
</Container>
}
