import React, { useEffect, useState } from 'react';
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import axios, { AxiosError } from 'axios';
import { contractNFTAddress, MICROSERVICE_API_URL } from 'config';
import { Button, Col, Row } from 'react-bootstrap';
import { TransactionPayload } from '@multiversx/sdk-core/out';
import { numHex } from 'utils/numHex';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { NFTToUnstake } from 'types/NFTToUnstake';
import { NFTUnbond } from 'types/NFTUnbond';
import { NftImage } from 'components/NftImage';
import Timer from 'components/Timer';

export const NftsUnbond = () => {
  const { address } = useGetAccountInfo();
  const { success, fail } = useGetActiveTransactionsStatus();
  const [nfts, setNfts] = useState<NFTUnbond[]>([]);
  const [selectable, setSelectable] = useState<boolean>(false);
  const [nftsToUnstake, setNftsToUnstake] = useState<NFTToUnstake[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();

  const fetchNftsUnbond = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(MICROSERVICE_API_URL + '/stake/nfts/unbond/users/' + address);
      setNfts(data);
    } catch (err) {
      const { message } = err as AxiosError;
      setError(message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (success || fail) {
      fetchNftsUnbond()
    }
  }, [success, fail]);

  useEffect(() => {
    fetchNftsUnbond();
  }, []);

  const includeNft = (nonce: number) => {
    for(const nft of nftsToUnstake) {
      if(nft.nonce === nonce) return true;
    }
    return false;
  }

  const handleChange = (identifier: string, nonce: number, deadline: number) => {
    let current_time = Date.now();
    // if(deadline * 1000 > current_time) {
    //   alert('UnStake time has not expired!');
    //   return
    // };
    if(includeNft(nonce)) {
      setNftsToUnstake((prev) => [...prev.filter((nft) => nft.nonce !== nonce)]);
    } else {
      setNftsToUnstake((prev) => [...prev, {identifier, nonce}])
    }
  }
  
  const createData = () => {
    let data = 'unBond';

    for(const nft of nftsToUnstake) {
      data += "@" + Buffer.from(nft.identifier).toString("hex") + "@" + numHex(nft.nonce);
    }
    return data;
  }

  const sendUnbondTransaction = async () => {
    const unStakeTransaction = {
      value: 0,
      data: new TransactionPayload(createData()),
      receiver: contractNFTAddress,
      gasLimit: '60000000'
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: unStakeTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Unbond transaction',
        errorMessage: 'An error has occured during Unbond',
        successMessage: 'Unbond transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      // setTransactionSessionId(sessionId);
    }
    setNftsToUnstake([]);
    setSelectable(false);
  };

  if (nfts.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
        <img
          src={require('../../../assets/img/no-nft.png')}
          alt='no nfts'
          width={'30%'}
          className='mb-4'
        />
      </div>
    );
  }

  return (
    <>
    <Row>
      <Col>
        {selectable ? (
        <div className='d-flex justify-content-center mb-3'>
          <Button className='btn-custom font-studly-regular' onClick={() => {setSelectable(false), setNftsToUnstake([])}}>
            Cancel
          </Button>
          {nftsToUnstake.length > 0 ? <Button className='btn-custom font-studly-regular ml-2' onClick={sendUnbondTransaction}>
            UnBond
          </Button> : null}
        </div>
        ) : <Button className='btn-custom font-studly-regular d-block mx-auto mb-3' onClick={() => setSelectable(true)}>
          Select
        </Button>}
      </Col>
    </Row>
      <Row>
        {nfts?.map((nft) => (<Col sm={12} md={3} key={nft.identifier + nft.nonce}>
          {
            selectable ? (
              <div
                onClick={() => handleChange(nft.identifier, nft.nonce, nft.deadline)}
              >
                <NftImage className={includeNft(nft.nonce) ? "nft-selcted" : undefined} identifier={nft.identifier + '-' + numHex(nft.nonce)} />
                <Timer deadline={Number(nft.deadline * 1000)} />
                <p className='text-center text-white font-trocchi mt-2'>{nft.identifier + '-' + nft.nonce}</p>
              </div>
          ) : (
            <div>
              <NftImage identifier={nft.identifier + '-' + numHex(nft.nonce)} />
              <Timer deadline={Number(nft.deadline * 1000)} />
              <p className='text-center text-white font-trocchi mt-2'>{nft.identifier + '-' + nft.nonce}</p>
            </div>
          )
          }
        </Col>))}
      </Row>
    </>
  );
};
