import { FC } from 'react'
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { contractSFTAddress } from 'config';

export const RewardCard: FC<Props> = ({ rewards, contractAddress }) => {
    const claimRewards = async () => {
        const claimRewardsTransaction = {
          value: 0,
          data: "claimRewards",
          receiver: contractAddress,
          gasLimit: '60000000'
        };
        await refreshAccount();
    
        const { sessionId /*, error*/ } = await sendTransactions({
          transactions: claimRewardsTransaction,
          transactionsDisplayInfo: {
            processingMessage: 'Processing Claim transaction',
            errorMessage: 'An error has occured during Claim',
            successMessage: 'Claim transaction successful'
          },
          redirectAfterSign: false
        });
        if (sessionId != null) {
          // setTransactionSessionId(sessionId);
        }
        }

  return (
    <div className="reward-card">
        <h4 className="card-title text-center">
            REWARD
        </h4>
        <div className='text-center'>
            <div>
                <h5 className="text-white font-trocchi">Your reward: {rewards} $XAPES</h5>
                <button
                    className={`btn-custom font-studly-regular mt-2 ${rewards == 0 && 'btn-disabled'}`}
                    disabled={rewards > 0 ? false : true}
                    onClick={claimRewards}
                >
                    Claim
                </button>
            </div>
        </div>
    </div>
  )
}

interface Props {
    rewards: number;
    contractAddress: string
}