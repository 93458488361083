import React, { useEffect, useState } from 'react';
import { useGetAccountInfo, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import axios, { AxiosError } from 'axios';
import { contractSFTAddress, MICROSERVICE_API_URL, SFT_TOKEN_MEDIA, SFT_TOKEN_ID } from 'config';
import { Button, Modal } from 'react-bootstrap';
import { TransactionPayload } from '@multiversx/sdk-core/out';
import { numHex } from 'utils/numHex';
import { refreshAccount } from '@multiversx/sdk-dapp/utils';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { SFTUnbond } from 'types/SFTUnbond';
import Timer from 'components/Timer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

export const SftsUnbond = () => {
  const { address } = useGetAccountInfo();
  const { success, fail } = useGetActiveTransactionsStatus();
  const [sft, setSft] = useState<SFTUnbond>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [show, setShow] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(1);

  const fetchSftsUnbond = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(MICROSERVICE_API_URL + '/stake/sfts/unbond/users/' + address);
      setSft(data);
    } catch (err) {
      const { message } = err as AxiosError;
      setError(message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (success || fail) {
      fetchSftsUnbond()
    }
  }, [success, fail]);

  useEffect(() => {
    fetchSftsUnbond();
  }, []);

  const increase = () => {
    if(amount >= Number(sft?.amount)) {
      return;
    }
    setAmount((prev) => prev + 1);
  }

  const decrease = () => {
    if(amount === 1) {
      return;
    }
    setAmount((prev) => prev - 1);
  }

  const sendUnbondTransaction = async () => {
    const data = "unBond" + "@" + Buffer.from(SFT_TOKEN_ID).toString("hex") + "@" + numHex(1) + "@" + numHex(amount);
    const unStakeTransaction = {
      value: 0,
      data: new TransactionPayload(data),
      receiver: contractSFTAddress,
      gasLimit: '60000000'
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: unStakeTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Unbond transaction',
        errorMessage: 'An error has occured during Unbond',
        successMessage: 'Unbond transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      // setTransactionSessionId(sessionId);
    }
    setAmount(1);
    setShow(false);
  };

  if (!sft) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
        <img
          src={require('../../../assets/img/no-nft.png')}
          alt='no nfts'
          width={'30%'}
          className='mb-4'
        />
      </div>
    );
  }

  return (
    <>
      <div>
        <img src={SFT_TOKEN_MEDIA} width="100%" alt="sft" className='d-block mx-auto mb-3 nft-image ' />
        <h5 className='text-white text-center font-trocchi mb-3'>x{sft.amount}</h5>
        <button className='btn-custom font-studly-regular d-block mx-auto'  onClick={() => setShow(true)}>UnBond</button>
        <Timer deadline={sft.deadline * 1000} />
      </div>
      <Modal
      show={show}
      onHide={() => setShow(false)}
      backdropClassName='jungleversx-modal'
      className='jungleversx'
      centered
    >
        <Modal.Body className='jungleversx-modal-body'>
          <h2 className='text-center text-white font-studly-regular mt-2 mb-4'>Set amount</h2>
          <div className="mb-5">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faMinus} fontSize={30} color='#29F9DE' onClick={decrease} />
              <h1 className='text-center text-white font-studly-regular mx-3'>{amount}</h1>
              <FontAwesomeIcon icon={faPlus} fontSize={30} color='#29F9DE' onClick={increase} />
            </div>
            <Button
              className='btn-custom font-studly-regular d-block mx-auto'
              onClick={() => setAmount(Number(sft.amount))}
            >Max</Button>
            {error && <div className="form-text">{error}</div>}
          </div>
          <div className="d-flex justify-content-end">
            <Button variant='outline' className='text-white font-trocchi' onClick={() => setShow(false)}>
              Close
            </Button>
            <Button className='btn-custom font-studly-regular' disabled={error ? true : false} onClick={sendUnbondTransaction}>
              UnBond
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
